// import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import React, { useContext, useEffect } from 'react'
import { 
  // GlobalStateContext,
  GlobalDispatchContext 
} from 'src/context/GlobalContextProvider'

function WindowDriver() {
  // const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)


  /*      1 SPROBOWAC ZAPISAC ZEBRANE DANE NAJPIERW DO LOKAL STATE, A DOPIERO POTEM DO CONTEXT    */
  /*      2 SPROBOWAC UZYC 'GETBOUNDINGCLIENTRECTANGLE'    */
  /*      3 SPROBOWAC ZROBIC MIERZENIE OKNA W KAZDYM KOMPONENCIE, KTORY POTRZEBUJE WYNIKOW POMIAROW, Z OSOBNA, ZAMIAST ZAPISYWAC JE DO KOTEKSTU    */
  /*      4 SPROBOWAC ULOKOWAC WINDOWDRIVER W 'PORTAL BOTTOM' ALBO NAWET W '#GLOBAL WRAPER'     */

  const handleResize = () => {

    /*    OFFSET    */
    typeof document !== `undefined` && dispatch({ type: 'WINDOW_WIDTH', value: document.body.offsetWidth })
    typeof document !== `undefined` && dispatch({ type: 'WINDOW_HEIGHT', value: document.body.offsetHeight })

    if((document.body.offsetWidth - 200) * 0.5625 > (document.body.offsetHeight - 200)) {
      dispatch({ type: 'WINDOW_MODE', value: 'wider' })
    } else if((document.body.offsetWidth - 200) * 0.5625 < (document.body.offsetHeight - 200)) {
      dispatch({ type: 'WINDOW_MODE', value: 'higher' })
    } else {
      dispatch({ type: 'WINDOW_MODE', value: 'higher' })
    }

    /*    INNER   */
    // dispatch({ type: 'WINDOW_WIDTH', value: window.innerWidth })
    // dispatch({ type: 'WINDOW_HEIGHT', value: window.innerHeight })

    // if((window.innerWidth - 200) * 0.5625 > (window.innerWidth - 200)) {
    //   dispatch({ type: 'WINDOW_MODE', value: 'wider' })
    // } else if((window.innerWidth - 200) * 0.5625 < (window.innerHeight - 200)) {
    //   dispatch({ type: 'WINDOW_MODE', value: 'higher' })
    // } else {
    //   dispatch({ type: 'WINDOW_MODE', value: 'higher' })
    // }




    



    // dispatch({ type: 'WINDOW_WIDTH', value: document.body.clientWidth })
    // dispatch({ type: 'WINDOW_HEIGHT', value: document.body.scrollHeight })

    // dispatch({ type: 'WINDOW_WIDTH', value: window.innerWidth })
    // dispatch({ type: 'WINDOW_HEIGHT', value: window.innerHeight })

    // windowMode()
    
    // console.log(`document.body.offsetWidth ${document.body.offsetWidth}`)
    // console.log(`document.body.clientWidth ${document.body.clientWidth}`)
    // console.log(`document.body.scrollWidth ${document.body.scrollWidth}`)
    // console.log(`document.body.offsetHeight ${document.body.offsetHeight}`)
    // console.log(`document.body.clientHeight ${document.body.clientHeight}`)
    // console.log(`document.body.scrollHeight ${document.body.scrollHeight}`)
  }

  // const windowMode = () => {
  //   if(state.windowWidth * 0.5625 > state.windowHeight) {
  //     dispatch({ type: 'WINDOW_MODE', value: 'wider' })
  //   } else if(state.windowWidth * 0.5625 < state.windowHeight) {
  //     dispatch({ type: 'WINDOW_MODE', value: 'higher' })
  //   } else {
  //     dispatch({ type: 'WINDOW_MODE', value: 'higher' })
  //   }
  // }

  // const windowMode = () => {
  //   if((state.windowWidth - 200) * 0.5625 > (state.windowHeight - 200)) {
  //     dispatch({ type: 'WINDOW_MODE', value: 'wider' })
  //   } else if((state.windowWidth - 200) * 0.5625 < (state.windowHeight - 200)) {
  //     dispatch({ type: 'WINDOW_MODE', value: 'higher' })
  //   } else {
  //     dispatch({ type: 'WINDOW_MODE', value: 'higher' })
  //   }
  // }


  useEffect(() => {
  // useLayoutEffect(() => {

    // setTimeout(window.addEventListener('resize', handleResize, true), 500)
    window.addEventListener('resize', handleResize, true)

    // setTimeout(handleResize, 100)
    handleResize()
    // windowMode()

    return () => {
      window.removeEventListener('resize', handleResize, true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  /*    SCREEN WIDER OR HIGHER ?    */
  // useEffect(() => {
  // // useLayoutEffect(() => {

  //   window.addEventListener('resize', windowMode, true)

  //   // setTimeout(windowMode, 100)
  //   windowMode()

  //   return () => {
  //     window.removeEventListener('resize', windowMode, true)
  //   }
  // }, [state.windowWidth, state.windowHeight]) // eslint-disable-line react-hooks/exhaustive-deps




  // const updateMousePosition = ev => {
  //   dispatch({ type: 'MOUSE_X', value: ev.clientX })
  //   dispatch({ type: 'MOUSE_Y', value: ev.clientY })
  // }
  // useEffect(() => {
  //   window.addEventListener('mousemove', updateMousePosition)
  //   return () => {
  //     window.removeEventListener('mousemove', updateMousePosition)
  //   }
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
    </>
  )
}
export default WindowDriver