import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'


const ExtScript = ({ popup, pagePath }) => {

  const [iframeWidth, setIframeWidth] = useState({})

  const popupCode = (typeof popup === 'object' && typeof popup?.acfpopupfields.freshmailScriptPopupFields === 'string') ? popup.acfpopupfields.freshmailScriptPopupFields : null

  // HANDLE SCRIPT
  const popupScriptStart = popupCode ? popupCode.indexOf('<script>') + 8 : null
  const popupScriptEnd = popupCode ? popupCode.indexOf('</script>') : null
  const popupScript = popupCode ? popupCode.slice(popupScriptStart, popupScriptEnd) : null
  
  // HANDLE IFRAME
  const popupIframe = popupCode ? popupCode.slice(popupCode.indexOf('<iframe')) : null

  useEffect(() => {
    if(popupScript) {

      const inlineScript = document.createElement('script');
      inlineScript.innerHTML = popupScript
      document.body.appendChild(inlineScript)
      return () => {
        document.body.removeChild(inlineScript)
      }
    }
  },[pagePath])  
  

  const bodyWidth = typeof document !== `undefined` && (document.body.offsetWidth > 444)  ///  THIS COULD BE PROBLEM, CHECK BODY OF USEEFFECT BELOW

  useLayoutEffect(() => {
    const timer = setTimeout(() => {

      bodyWidth && setIframeWidth({width:'400px'})

      // setIframeWidth({width:'400px'})

    }, 1400);
    return () => clearTimeout(timer)
  }, [bodyWidth])
  // }, [])



  return(
    <div 
      className={`freshmailIframe`}
      style={iframeWidth}
    >
      {parse(popupIframe || '')}      
    </div>
  )
}


const ExtScriptBuffer = ({ pagePath }) => {
  const graphqlResult = useStaticQuery(graphql`
    query Popups {
      allWpCpTpopup {
        nodes {
          slug
          databaseId
          modified
          acfpopupfields {
            freshmailScriptPopupFields
            locationPopupFields
            showPopupFields
          }
        }
      }
    }
  `)


  const popupToShowGlobal = graphqlResult.allWpCpTpopup.nodes.find(el => el.acfpopupfields.locationPopupFields === null)
  const popupToShowLocal = graphqlResult.allWpCpTpopup.nodes.find(el => el.acfpopupfields.locationPopupFields === pagePath)

  
  // LOCAL POPUP ID
  const idLocal = typeof popupToShowLocal !== 'undefined' ? `pu-l-${popupToShowLocal.databaseId}-${Date.parse(popupToShowLocal.modified)}` : null
  // GLOBAL POPUP ID
  const idGlobal = typeof popupToShowGlobal !== 'undefined' ? `pu-g-${popupToShowGlobal.databaseId}-${Date.parse(popupToShowGlobal.modified)}` : null
  
    
  
  const storageIncLoc = (typeof localStorage !== 'undefined' && localStorage !== null) ? localStorage.getItem(idLocal) : null
  const storageIncGlob = (typeof localStorage !== 'undefined' && localStorage !== null) ? localStorage.getItem(idGlobal) : null

  const popupToShow = (!storageIncLoc && typeof popupToShowLocal === 'object') ? popupToShowLocal : ((!storageIncGlob && typeof popupToShowGlobal === 'object') ? popupToShowGlobal : null)

  const popupUsed = (!storageIncLoc && typeof popupToShowLocal === 'object') ? 'local' : ((!storageIncGlob && typeof popupToShowGlobal === 'object') ? 'global' : null)

  const key = popupUsed === 'local' ? idLocal : idGlobal

  // console.log(key)
  // console.log(idGlobal)
  // console.log(idLocal)
  // console.log(storageIncGlob)
  // console.log(storageIncLoc)
  // console.log(popupToShow)
  // console.log(popupUsed)
  // console.log(visited)


  const initVisited = () => {
    let status = false
    if (typeof localStorage !== 'undefined' && localStorage !== null) {
      const test = localStorage.getItem(key)
      if(test) {
        status = true
      }
    }
    return status
  }

  const [visited, setVisited] = useState(() => initVisited())

  const initShowPopup = () => {
    let status = false
    if (typeof localStorage !== 'undefined' && localStorage !== null) {
      const test = localStorage.getItem(key)
      if(!test) {
        status = true
      }
    }
    return status
  }

  const [showPopup, setShowPopup] = useState(() => initShowPopup())
  const [fadeOut, setFadeOut] = useState(false)
  const [ready, setReady] = useState(false)
 

  const removePopup = () => {
    ready && setFadeOut(true)
  }

  useLayoutEffect(() => {
    const timer = setTimeout(() => {      
      (fadeOut && showPopup) && setShowPopup(false)
      fadeOut && setFadeOut(false)
      !ready && setReady(true)

      
      // (!visited && fadeOut) && setVisited(true)

      // const key = popupUsed === 'local' ? idLocal : (popupUsed === 'global' ? idGlobal : `malf=${pagePath}`)
      // !visited && window.localStorage.setItem(key, 'visited')

      // !visited && setVisited(true)

    }, 550);
    return () => clearTimeout(timer)
  }, [fadeOut])

  useEffect(() => {
    !showPopup && window.localStorage.setItem(key, 'visited')
  },[showPopup])


  return(
    <>

      {(!visited && showPopup) && 
      <div className={`freshmailWrap ${fadeOut ? 'fadeOut' : ''}`} >

        <div className={`freshmailWrapInner`}>

          <ExtScript pagePath={pagePath} popup={popupToShow} />

          <span className={`backLink cross`} >
            <FontAwesomeIcon icon={faTimes}/>
          </span>

        </div>

        <div
          className={`closePopup`}
          onClick={removePopup}
          onKeyDown={removePopup}
          role="button"
          tabIndex="0"
          aria-label='Close modal form'

          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '2',
            backgroundColor: 'transparent'
          }}
        >
        </div>

      </div>
      }

    </>
  )
}
export default ExtScriptBuffer