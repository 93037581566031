import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons'


const LangSwitchUnit = ( site ) => {
  const graphqlResult = useStaticQuery(graphql`
    query siteLangMob {
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
    }
  `)
  const currentLang = graphqlResult.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo

  const slugMap = {
    'pl-PL': {
      brand: 'nasza-marka',
      sport: 'sport',
      // business: 'biznes',
      business: 'okolicznosciowe',
    },
    'en-EN': {
      brand: 'our-brand',
      sport: 'sport',
      // business: 'business',
      business: 'occasional',
    },
    'de-DE': {
      brand: 'unsere-marke',
      sport: 'sport',
      // business: 'geschaeft',
      business: 'Sonderveranstaltungen',
    }
  }

  // const sitesEcosys = [
  //   { lang: 'pl-PL', link: 'https://modernformspwa2021.wydajnyteam.pl/', label: 'PL' },
  //   { lang: 'en-EN', link: 'https://themodernforms.com/', label: 'EN' },
  //   { lang: 'de-DE', link: 'https://front.mf-admin.pl/', label: 'DE' }
  // ]
  const sitesEcosys = [
    { lang: 'pl-PL', link: 'https://modernforms.pl/', label: 'polski' },
    { lang: 'en-EN', link: 'https://themodernforms.com/', label: 'english' },
    { lang: 'de-DE', link: 'https://modernforms.de/', label: 'deutsch' }
  ]

  const siteParam = site.site ? site.site : ''

  const sitesFiltred = sitesEcosys.map((item) => {
    return item.lang !== currentLang ? { lang: item.lang, link: `${item.link}${siteParam !== '' ? `${slugMap[item.lang][siteParam]}/` : ''}`, label: item.label } : { lang: '', link: item.label, label: item.label } 
  })

  let currentInd
  let currentObj
  for(let i = 0; sitesFiltred.length > i; i++) {
    if(sitesFiltred[i].lang === '') {
      currentInd = i
      currentObj = sitesFiltred[i]
    } 
  }
  sitesFiltred.splice(currentInd, 1)
  sitesFiltred.splice(0, 0, currentObj)

  const[dropdown, setDropdown] = useState(false)


  return (
    <>
      {sitesFiltred && sitesFiltred.map((site, i, arr) => {
        // const lastIndex = arr.length - 1;
        if (site.link === site.label) {
          return(
            <span 
              key={i} 
              className={`langMob`} 
              onClick={() => setDropdown(dropdown ? false : true)}
              onKeyDown={() => setDropdown(dropdown ? false : true)}
              tabIndex="1"
            >
              <a href="#" style={{position:'relative',zIndex:'10000000'}}>
                <span style={{fontWeight: '300',fontSize:'14px'}}>{site.label}</span>
                {!dropdown ? <FontAwesomeIcon icon={faChevronDown} style={{marginLeft:'8px',width:'12px',height:'12px'}} /> : <FontAwesomeIcon icon={faChevronUp} style={{marginLeft:'8px',width:'12px',height:'12px'}} />}
              </a>
              {/* {i !== lastIndex && <span style={{fontWeight: '700'}}> / </span>} */}
            </span>
          )
        } else {
          return(
            <span key={i} className={`langMob hidden ${dropdown ? 'activate' : ''}`}>
              <a href={site.link} style={{position:'relative',zIndex:'10000000'}} rel="noreferrer">
                <span style={{fontWeight: '300',fontSize:'14px',opacity:'0.8'}}>{site.label}</span>
              </a>
              {/* {i !== lastIndex && <span style={{fontWeight: '700'}}> / </span>} */}
            </span>
          )
        }
      })}

      {dropdown && <div 
        style={
          {
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            cursor: 'pointer',
            zIndex: '1000',

          }
        }
        onClick={() => setDropdown(dropdown ? false : true)}
        onKeyDown={() => setDropdown(dropdown ? false : true)}
        tabIndex="1"
      ></div>}

    </>
  )
}
export default LangSwitchUnit