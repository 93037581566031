import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import {
  GlobalStateContext,
} from 'src/context/GlobalContextProvider'


// export const Seo = ({ description, keywords, title, image, url, author }) => {
const SEO = ({ seo, site, slug, gatsbyId, subpageid, subpage2levid, shared, siteSlug }) => {
  const state = useContext(GlobalStateContext)  
  const data = useStaticQuery(graphql`
    query DefaultSeoQuery {
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      wp {
        generalSettings {
          description
          title
        }
        seo {
          schema {
            siteName
            wordpressSiteName
            siteUrl
            inLanguage
            companyName
            companyOrPerson
            companyLogo {
              mediaItemUrl
            }
            logo {
              mediaItemUrl
            }
            personLogo {
              mediaItemUrl
            }
          }
          breadcrumbs {
            showBlogPage
            separator
            searchPrefix
            prefix
            homeText
            enabled
            boldLast
            archivePrefix
            notFoundText
          }
          social {
            facebook {
              url
              defaultImage {
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              cardType
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
          openGraph {
            frontPage {
              title
              description
              image {
                altText
                sourceUrl
                mediaItemUrl
              }
            }
            defaultImage {
              altText
              sourceUrl
              mediaItemUrl
            }
          }
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
              schema {
                raw
              }
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
              schema {
                raw
              }
            }
          }
          redirects {
            origin
            target
            format
            type
          }
        }
      }
    }
  `)




  const metaDescription = seo.metaDesc || data.wp.generalSettings.description
  const metaTitle = seo.title || data.wp.generalSettings.title
  // url
  // author
  // image
  const metaKeywords = [seo.focuskw] || ["", ""]
  const lang = data.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo
  const bodyLang = lang === 'pl-PL' ? 'pl' : lang === 'en-EN' ? 'en' : lang === 'de-DE' ? 'de' : ''
  const swipeNavClass = state.swipeNav === true ? 'swipeNav' : ''

  if(typeof site === 'undefined') {
    site = 'shared'
  }

  // console.log(seo)
  // console.log(data.site.siteMetadata.siteUrl)
  // console.log(slug)
  // console.log(gatsbyId)
  // console.log(shared)
  // console.log(site)


  // const fbVerId = lang === 'pl-PL' ? '2a838n5ocrrgphawfo6i7cj4peh842' : lang === 'de-DE' ? 'lnpw2d8dethq1t1sua21l7k4qds7f0' : 'grfir691sjacti96tmd41wasrqe97g'


  return(
    <Helmet
      bodyAttributes={{ class: `body-${site} ${gatsbyId ? `body-${site}-${gatsbyId} body-${gatsbyId}` : '' } ${state.menu ? `menu-open` : ''} body-lang-${bodyLang} ${swipeNavClass} ${subpageid ? `body-${subpageid} body-subpage` : ''} ${subpage2levid ? `${subpage2levid}` : ''}` }}
      title={metaTitle}
      meta={[
        {
          name: `robots`,
          content: `${gatsbyId === '404' ? 'noindex' : 'index'}, follow`
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `viewport`,
          content:
            "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
        },
        // {
        //   property: `facebook-domain-verification`,
        //   content: fbVerId,
        // },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,

          content:
          !shared ?
          `${data.site.siteMetadata.siteUrl}/${siteSlug}/${slug}`
          :
          `${data.site.siteMetadata.siteUrl}/${slug}`
          ,

        },
        {
          property: `og:image`,
          content: data.wp.seo.openGraph.defaultImage?.mediaItemUrl,
        },
        {
          property: `og:locale`,
          content: lang,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        // {
        //   name: `twitter:creator`,
        //   content: wpUser?.twitter || ``,
        // },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: lang === 'en-EN' ? "p:domain_verify" : '',
          content: lang === 'en-EN' ? "072eac49cdfc643d4169a5e153b38c5b" : '',
        }
      ].concat(
          metaKeywords && metaKeywords.length > 0 ? {
            name: `keywords`,
            content: metaKeywords.join(`, `)
          } : []
        )
      }
    >
      {(typeof seo.canonical !== 'undefined' && seo.canonical !== "" && seo.canonical !== null) && <link rel="canonical" 
        href={`${
          (seo.canonical.substring(0, 1) !== '/' ? seo.canonical :
          (gatsbyId === 'start') ?
          data.site.siteMetadata.siteUrl + seo.canonical.substring(0, seo.canonical.lastIndexOf('modern-forms/'))
          :
          data.site.siteMetadata.siteUrl + seo.canonical)
        }`} 
      />}

      <script type="application/ld+json">
        {/* {JSON.stringify(schemaMarkup)} */}
        {seo.schema?.raw ? seo.schema.raw : data.wp.seo.contentTypes.post.schema.raw}
      </script>

      {/* {state.swipeNav &&
        ( lang === 'de-DE' ?
            <script type="application/javascript" src={'/jsInitialPopupDE.js'} defer />
          :
          lang === 'pl-PL' ?                
            <script type="application/javascript" src={'/jsInitialPopupPL.js'} defer />
          :                
            <script type="application/javascript" src={'/jsInitialPopup.js'} defer />
        )
      } */}
      {state.swipeNav && <script type="application/javascript" src={'/jsInitialPopup.js'} defer />}
      
      <html lang={lang} />
      
    </Helmet>
  )
}

export default SEO